<template>
  <div class="data-table">
    <div class="table-data" v-loading="loading" element-loading-text="Loading..." >
      <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="feedbackData" style="width: 100%" class="table-height"
                :row-style="{ height: '55px' }">
        <el-table-column prop="contactName" :label="$t('HistoryData.CustomerFeedback.contacts')"
                         ></el-table-column>
        <el-table-column prop="contactPhone" :label="$t('HistoryData.CustomerFeedback.phoneNumber')"
                         ></el-table-column>
        <el-table-column prop="remark" :label="$t('HistoryData.CustomerFeedback.remarks')"
                         ></el-table-column>
        <el-table-column prop="feedbackTime" :label="$t('HistoryData.CustomerFeedback.timeOfFeedback')"
                         ></el-table-column>
        <el-table-column :label="$t('HistoryData.CustomerFeedback.diagnosisReport')" >
          <template v-slot="scope">
            <a :href="scope.row.file_url" target="_blank"
               class="link-text-style">{{ $t('HistoryData.CustomerFeedback.view') }}</a>
          </template>
        </el-table-column>

        <template v-slot:empty>
          <EmptySlot/>
        </template>

      </el-table>
    </div>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/resizeMixin';
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot},
  data() {
    return {
    }
  },
  mixins: [resizeMixin],
  props: {
    feedbackData: Array, // 反馈数据数组
    loading: Boolean  // 接收 loading 状态
  },
};
</script>
<style scoped>

.data-table {
  margin: 10px 15px -5px 24px;

  @media (max-width: 768px) {
    margin: 10px 15px -5px 24px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 10px 15px -5px 24px;
  }

  /* 添加其他屏幕尺寸的媒体查询和 margin 设置 */
}
</style>
  