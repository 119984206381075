<template>
  <div class="button-component">
    <!-- 使用 el-tooltip 包裹按钮 -->
    <el-tooltip :content="$t('quickDiagnosis.selectDirectory')" placement="top" :disabled="!isDisabled">
      <el-button type="info" @click="addFAQ" :disabled="isDisabled" :selectedNodeId="selectedNodeId"
                 :class="{ 'custom-button': true, 'button-active': !isDisabled }">
        <el-icon>
          <Plus/>
        </el-icon>&nbsp;{{ $t('quickDiagnosis.addQuickDiagnosis') }}
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";

export default {
  components: {Plus},
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    selectedNodeId: {
      type: [String, Number], // 依据实际情况这里可能只是String或只是Number
      default: null
    },
  },
  methods: {
    addFAQ() {
      this.$router.push({
        name: 'AddFAQ',
        params: { selectedNodeId: this.selectedNodeId }
      });
    },

  },
};
</script>

<style scoped>
/* 样式 */
.button-component {
  display: flex;
  margin-top: 20px;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

/* 当按钮可点击时应用的样式 */
.button-active {
  background-color: white; /* 设置背景为白色 */
  color: #606266; /* 可以调整文字颜色以保持可读性 */
  border-color: #dcdfe6; /* 可选：设置边框颜色 */
}

/* 悬停在 .button-active 上时的样式 */
.button-active:hover {
  color: rgb(0, 154, 68); /* 悬停时文字颜色为绿色 */
  border-color: rgb(0, 154, 68); /* 悬停时边框颜色为绿色 */
  background-color: white; /* 悬停时背景色为白色 */
}
</style>
  