<template>
  <div class="data-table">
    <div class="table-data" v-loading="loading" element-loading-text="Loading...">
      <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="data" style="width: 100%"
                :row-style="{ height: '55px' }" class="table-height">

        <el-table-column prop="serialNumber" :label="$t('HistoryData.UpdateRecord.serialNumber')"/>
        <el-table-column prop="productType" :label="$t('HistoryData.UpdateRecord.typeOfProduct')"/>

        <el-table-column prop="modelCode" :label="$t('ProductUpgrade.model')"/>

        <el-table-column prop="firmwareUpgrade" :label="$t('HistoryData.UpdateRecord.firmwareUpgrade')"/>
        <el-table-column prop="parameterUpgrade" :label="$t('HistoryData.UpdateRecord.parameterUpgrade')"/>

        <el-table-column prop="oldVersion" :label="$t('HistoryData.UpdateRecord.preUpgrade')"/>
        <el-table-column prop="newVersion" :label="$t('HistoryData.UpdateRecord.upgradeSoftware')"/>

        <el-table-column prop="Status" :label="$t('HistoryData.UpdateRecord.upgradeResult')">
          <template #default="scope">
          <span>
            {{
              scope.row.Status === 1 ? $t('HistoryData.UpdateRecord.success') : $t('HistoryData.UpdateRecord.failure')
            }}
          </span>
          </template>
        </el-table-column>

        <el-table-column prop="Message" :label="$t('HistoryData.UpdateRecord.upgradeMessage')">
          <template #default="scope">
            <el-tooltip effect="dark" placement="top-start" :content="scope.row.Message">
          <span>
            {{ truncatedText(scope.row.Message) }}
          </span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="upgradeTime" :label="$t('HistoryData.UpdateRecord.timeOfUpgrade')"/>
        <el-table-column prop="upgradePerson" :label="$t('HistoryData.UpdateRecord.ThePersonIn')"/>
        <el-table-column prop="upgradeOrganization" :label="$t('HistoryData.UpdateRecord.upgradeOrganization')"/>

        <template v-slot:empty>
          <EmptySlot/>
        </template>

      </el-table>
    </div>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/resizeMixin';
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot},
  data() {
    return {}
  },
  mixins: [resizeMixin, dialogMixin],
  props: {
    data: Array, // 表格数据
    loading: Boolean  // 接收 loading 状态
  },
};


</script>
<style scoped>
.table-data {
  margin-top: 20px;
}

.data-table {
  margin: 10px 15px -5px 24px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 10px 15px -5px 24px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 10px 15px -5px 24px;
  }

}
</style>