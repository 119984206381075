<template>
  <div class="data-table">
    <div class="table-display" v-loading="loading" element-loading-text="Loading...">
      <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" :row-style="{ height: '55px' }"
                class="table-height">
        <el-table-column prop="diagnosticTime" :formatter="formatDate" class-name="column-diagnosticTime"
                         :label="$t('HistoryData.diagnosisReport.timeOfDiagnosis')">
        </el-table-column>
        <el-table-column prop="diagnosticPerson" class-name="column-diagnosticPerson"
                         :label="$t('HistoryData.diagnosisReport.thePersonIn')"></el-table-column>
        <el-table-column prop="diagnosticOrganization" class-name="column-diagnosticOrganization"
                         :label="$t('HistoryData.diagnosisReport.diagnosisOrganization')"></el-table-column>
        <el-table-column :label="$t('HistoryData.diagnosisReport.diagnosisReport')" class-name="column-diagnosisReport">
          <template v-slot="scope">
            <a :href="scope.row.file_url" target="_blank"
               class="link-text-style">{{ $t('HistoryData.diagnosisReport.view') }}</a>
          </template>
        </el-table-column>

        <template v-slot:empty>
          <EmptySlot/>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import resizeMixin from '@/mixins/resizeMixin';
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";


export default {
  components: {EmptySlot},
  data() {
    return {}
  },
  mixins: [resizeMixin, dialogMixin],
  props: {
    tableData: Array,
    loading: Boolean  // 接收 loading 状态
  },

  methods: {}
};
</script>
<style scoped>

.table-display {
  margin-top: 25px;
  margin-left: 0;

}

.data-table {
  margin: 10px 20px -5px 20px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 10px 20px -5px 20px;/* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 10px 20px -5px 20px; /* 在中等屏幕下增加间隔 */
  }

  /* 添加其他屏幕尺寸的媒体查询和 margin 设置 */
}
</style>
