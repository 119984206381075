<template>
  <div class="content-component">
    <!-- 搜索功能组件 -->
    <SearchBox @search="search" @reset="reset"/>

    <!-- 按钮组件 -->
    <ButtonAdd v-if="showAddButton" @addFAQ="addFAQ" :isDisabled="!isButtonEnabled" :selectedNodeId="selectedNodeId"/>

    <!-- 表格组件 -->
    <DataTable :faqData="faqData" :loadData="loadData" :loading="loading" :selectedNodeId="selectedNodeId"/>
    <PaginationBar :totalRecords="total" :current-page="currentPage" :page-size="pageSize"
                   @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>

  </div>
</template>

<script>
import SearchBox from "./contentComponents/SearchBox"; // 导入搜索功能组件
import ButtonAdd from "./contentComponents/ButtonAdd"; // 导入按钮组件
import DataTable from "./contentComponents/DataTable"; // 导入表格组件
import PaginationBar from "@/components/PaginationBar";
import {getFaqList} from "@/api/api"; // 导入分页组件

export default {
  components: {
    SearchBox,
    ButtonAdd,
    DataTable,
    PaginationBar,
  },
  props: {
    isButtonEnabled: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    selectedNodeId: {
      type: [String, Number],
      default: null,
    },
  },
  watch: {
    selectedNodeId() {
      this.loadData();
    },
    '$i18n.locale'() {
      this.loadData();
    },
  },
  data() {
    return {
      faqData: [],
      total: 0, // 数据总数
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handlePageChange(newPage) {
      this.currentPage = newPage;
      // 根据新的页码发送请求获取数据，并更新 tasks
      this.loadData(); // 重新加载数据
    },
    // 处理每页显示条数变化事件
    handlePageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      // 根据新的每页显示条数发送请求获取数据，并更新 tasks
      this.loadData(); // 重新加载数据
    },
    async search(searchParams) {
      this.isSearching = true;
      this.searchParams = searchParams;
      searchParams["sort-asc"] = 1;
      this.loading = true; // 开始加载
      try {
        let response;
        if (this.selectedNodeId) {
          searchParams["category_id-eq"] = this.selectedNodeId;
          // 如果有选择节点，根据节点ID加载数据
          response = await getFaqList(this.currentPage, this.pageSize, searchParams);
        } else {
          // 没有选择节点，加载全部数据
          response = await getFaqList(this.currentPage, this.pageSize, searchParams);
        }

        // 进行数据转换
        this.faqData = this.transformFaqData(response.data);

        this.total = response.meta.total; // 更新总记录数
      } catch (error) {
        // 使用 try/catch 捕获异步操作中的错误
        console.error(this.$t('ProductManage.searchFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },

    reset() {

    },
    addFAQ() {

    },

    transformFaqData(data) {
      const currentLang = this.$i18n.locale ? this.$i18n.locale : 'en';

      return data.map(item => {
        let content = item.contents.find(content => content.lang === currentLang);
        // 如果未找到当前语言的内容，默认使用英语
        if (!content) {
          content = item.contents.find(content => content.lang === 'en');
        }
        return {
          id: item.id,
          sorting: item.sort,
          faultCategory: item.last_category_name,
          faultCategoryId: item.category_id,
          faultDescription: content ? content.title : '', // 使用找到的内容
          productModel: item.product_type,
          number: item.no,
          updateTime: item.updated_at,
          status: item.status,
        };
      });
    },

    async loadData() {
      this.loading = true; // 开始加载
      try {
        let response;
        let searchCondition = {};

        searchCondition["sort-asc"] = 1;
        if (this.selectedNodeId) {
          searchCondition["category_id-eq"] = this.selectedNodeId;
          // 如果有选择节点，根据节点ID加载数据
          response = await getFaqList(this.currentPage, this.pageSize, searchCondition);
        } else {
          // 没有选择节点，加载全部数据
          response = await getFaqList(this.currentPage, this.pageSize, searchCondition);
        }
        // 进行数据转换
        this.faqData = this.transformFaqData(response.data);

        this.total = response.meta.total; // 更新总记录数
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
        // 出错时的处理逻辑，比如清空表格或显示错误消息
        this.faqData = [];
        this.total = 0;
      } finally {
        this.loading = false; // 加载结束
      }
    }

  },
};
</script>

<style scoped>

</style>