<template>
  <div class="history-search-bar">
    <el-form :model="searchForm" label-position="top">
      <el-row :gutter="20">

        <el-col :span="24" :sm="12" :md="7" :lg="7">
          <el-form-item :label="$t('HistoryData.UpdateRecord.serialNumber')">
            <el-input v-model="searchForm.serialNumber" ref="serialNumberInput"
                      :placeholder="$t('HistoryData.UpdateRecord.serialNumberPlaceholder')"
                      :clearable="true"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">
          <el-form-item :label="$t('HistoryData.UpdateRecord.typeOfProduct')">
            <el-select v-model="searchForm.productType" filterable
                       :placeholder="$t('HistoryData.UpdateRecord.typeOfProductPlaceholder')" :clearable="true"
                       class="custom-tree-select-width"
                       label-position="top">
              <el-option :label="$t('HistoryData.motor')" value="0"/>
              <el-option :label="$t('HistoryData.dashboard')" value="1"/>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">
          <el-form-item :label="$t('HistoryData.UpdateRecord.firmwareUpgrade')">

            <el-input v-model="searchForm.upgradeFirmwareFile"
                      :placeholder="$t('HistoryData.UpdateRecord.upgradeDocumentsPlaceholder')" :clearable="true"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">

          <el-form-item :label="$t('HistoryData.UpdateRecord.preUpgrade')">

            <el-input v-model="searchForm.oldVersion"
                      :placeholder="$t('HistoryData.UpdateRecord.preUpgradePlaceholder')" :clearable="true"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">

          <el-form-item :label="$t('HistoryData.UpdateRecord.upgradeSoftware')">

            <el-input v-model="searchForm.newVersion"
                      :placeholder="$t('HistoryData.UpdateRecord.upgradeSoftwarePlaceholder')" :clearable="true"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">
          <el-form-item :label="$t('HistoryData.UpdateRecord.timeOfUpgrade')">

            <el-date-picker v-model="searchForm.upgradeDate" type="datetimerange" :clearable="true"
                            :start-placeholder="$t('HistoryData.UpdateRecord.startDatePlaceholder')"
                            :popper-options="{
   modifiers: [
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['bottom'],
                allowedAutoPlacements: ['bottom'],
            }
        }
    ]
}"
                            :end-placeholder="$t('HistoryData.UpdateRecord.endDatePlaceholder')"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">

          <el-form-item :label="$t('HistoryData.UpdateRecord.ThePersonIn')">
            <el-input v-model="searchForm.upgradePerson"
                      :placeholder="$t('HistoryData.UpdateRecord.ThePersonInPlaceholder')" :clearable="true"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">
          <el-form-item :label="$t('HistoryData.UpdateRecord.upgradeOrganization')">
            <el-tree-select
                v-model="searchForm.upgradeOrganization"
                :data="organizationOptions"
                :props="{ label: 'label', children: 'children' }"
                check-strictly
                :placeholder="$t('HistoryData.UpdateRecord.pleaseSelectPlaceholder')"
                :render-after-expand="false"
                clearable filterable
                class="custom-tree-select-width"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24" :sm="12" :md="7" :lg="7">

          <el-form-item :label="$t('ProductUpgrade.model')">
            <el-input v-model="searchForm.model"
                      :placeholder="$t('ProductManage.pleaseEnterModel')" :clearable="true"/>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="24" :sm="12" :md="7" :lg="4">
          <el-form-item label="&nbsp;">
            <el-button class="button-background-color" @click="search">
              {{ $t('HistoryData.UpdateRecord.search') }}
            </el-button>
            <el-button @click="reset" class="custom-reset-button">
              {{ $t('HistoryData.UpdateRecord.reset') }}

            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


  </div>
</template>

<script>
import {getOrganizationsData} from "@/api/api";
import {listToTree} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  data() {
    return {
      searchForm: {
        serialNumber: '',
        productType: '',
        upgradeFirmwareFile: '',
        oldVersion: '',
        newVersion: '',
        upgradeDate: '',
        upgradePerson: '',
        upgradeOrganization: '',
        model: '',
      },
      organizationOptions: [],//省略，你需要根据组织结构定义数据
      selectWidth: 'auto', // 默认宽度或你的选择
    };
  },
  mixins: [dialogMixin],

  mounted() {
    this.$nextTick(() => {
      this.selectWidth = `${this.$refs.serialNumberInput.$el.offsetWidth}px`;
    });
    this.fetchOrganizationOptions();
  },
  methods: {
    search() {

      let searchCondition = {};

      // 只有当 searchForm.serialNumber 有值时，才添加到搜索条件中
      if (this.searchForm.serialNumber) {
        searchCondition["id-eq"] = this.searchForm.serialNumber;
      }
      if (this.searchForm.productType) {
        searchCondition["type-eq"] = this.searchForm.productType;
      }
      if (this.searchForm.oldVersion) {
        searchCondition["before_version-like"] = this.searchForm.oldVersion;
      }
      if (this.searchForm.newVersion) {
        searchCondition["after_version-like"] = this.searchForm.newVersion;
      }
      if (this.searchForm.upgradePerson) {
        searchCondition["user-name-like"] = this.searchForm.upgradePerson.trim();
      }

      if (this.searchForm.upgradeDate && this.searchForm.upgradeDate.length === 2) {
        const [start, end] = this.searchForm.upgradeDate;
        searchCondition["created_at-begin"] = this.formatDate(null, null, start); // 或者格式化为其他后端期望的格式
        searchCondition["created_at-end"] = this.formatDate(null, null, end); // 或者格式化为其他后端期望的格式
      }
      if (this.searchForm.upgradeOrganization) {
        searchCondition["organization_id-eq"] = this.searchForm.upgradeOrganization;
      }

      if (this.searchForm.upgradeFirmwareFile) {
        searchCondition["firmware_file_name-like"] = this.searchForm.upgradeFirmwareFile;
      }

      if (this.searchForm.model) {
        searchCondition["model_code-like"] = this.searchForm.model;
      }
      // 发送搜索请求并将搜索条件传递给父组件
      this.$emit('search', searchCondition);
    },
    reset() {
      // 重置搜索条件
      this.searchForm = {
        serialNumber: '',
        productType: '',
        upgradeFirmwareFile: '',
        oldVersion: '',
        newVersion: '',
        upgradeDate: '',
        upgradePerson: '',
        upgradeOrganization: '',
        model: '',
      };
      this.search();
    },
    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.fetchOrganizationFailed'));
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    }
  },
};
</script>
<style scoped>

</style>
  