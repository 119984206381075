<template>
  <div class="outer-container">
    <!-- 顶部区域 -->
    <div class="top-bar">
      <div class="left-text">{{ finalName }}</div>
      <div class="right-controls">
<!--        <el-select v-model="selectedLanguage" :placeholder="$t('quickDiagnosis.selectLanguage')" @change="addLanguage"
                   filterable class="language-select">
          <el-option v-for="language in languages" :key="language.value" :label="language.label"
                     :value="language.value"></el-option>
        </el-select>-->
        <el-button @click="cancel" class="custom-reset-button">{{ $t('quickDiagnosis.cancel') }}</el-button>
        <el-button class="button-background-color" @click="publish">{{ $t('quickDiagnosis.publish') }}</el-button>
      </div>
    </div>

    <div class="labels-and-selector">
      <!-- 动态语言标签 -->
      <div class="language-labels">
        <div v-for="(label, index) in languageLabels" :key="index"
             :class="{'active-label': label === currentLanguage, 'inactive-label': label !== currentLanguage}"
             class="language-label" @click="switchLanguage(label)">
          <span class="asterisk">*</span> {{ label }}
          <el-button link class="delete-button" @click.stop="removeLanguage(index)">
            <el-icon>
              <Close/>
            </el-icon>
          </el-button>
        </div>
      </div>

      <!-- 产品类型选择器 -->
      <div class="product-type-selector">
        <span class="required-asterisk">*</span>{{ $t('quickDiagnosis.productType') }}：&nbsp;&nbsp;&nbsp;
        <el-select v-model="selectedProductType" :placeholder="$t('quickDiagnosis.selectProductType')" filterable
                   class="custom-select">
          <el-option :label="$t('quickDiagnosis.midMount')" value="0"></el-option>
          <el-option :label="$t('quickDiagnosis.hub')" value="1"></el-option>
        </el-select>
      </div>
    </div>

    <!-- 文本框 -->
    <el-input
        type="textarea"
        v-model="languageContents[currentLanguage]"
        :rows="2"
        :maxlength="200"
        show-word-limit
        :placeholder="$t('quickDiagnosis.faultDescription')"
        class="text-area">
    </el-input>

    <!-- 编辑器 -->
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import {Close} from "@element-plus/icons-vue";
import {addFaqContents, addFaqs, showFaqsCategories} from "@/api/api";

export default {
  components: {Close},
  data() {
    return {
      quill: null,
      languages: [
        {label: '中文', value: 'zh'},
        {label: 'English', value: 'en'},
        // ...其他语言
      ],
      selectedLanguage: 'zh',
      languageLabels: ['中文', 'English'], // 初始时包含中文和英语标签
      languageContents: {}, // 存储不同语言的内容
      languageDetails: {},
      currentLanguage: '中文', // 当前选中的语言
      selectedProductType: '0', // 用于存储选中的产品类型

      selectedNodeId: null, // 添加属性来存储selectedNodeId
      categoryName: null,
      finalName: '',
    };
  },
  created() {
    if (this.$route.params.selectedNodeId) {
      // 从路由参数中获取selectedNodeId
      this.selectedNodeId = this.$route.params.selectedNodeId;
    }
  },
  mounted() {
    this.fetchCategory();
    this.$nextTick(() => {
      if (!this.quill) {
        // 使用之前的工具栏配置
        const toolbarOptions = [
          ['bold', 'italic', 'underline', 'strike'], // 加粗、斜体、下划线、删除线
          ['blockquote', 'code-block'], // 引用、代码块
          [{header: 1}, {header: 2}], // 标题
          [{list: 'ordered'}, {list: 'bullet'}], // 列表
          [{script: 'sub'}, {script: 'super'}], // 上标/下标
          [{indent: '-1'}, {indent: '+1'}], // 缩进
          [{direction: 'rtl'}], // 文本方向
          [{size: ['small', false, 'large', 'huge']}], // 字体大小
          [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题大小
          [{color: []}, {background: []}], // 字体颜色、背景颜色
          [{font: []}], // 字体
          [{align: []}], // 对齐方式
          ['clean'], // 清除格式
          ['link', 'image', 'video'] // 链接、图片、视频
        ];

        // 初始化 Quill
        this.quill = new Quill(this.$refs.editor, {
          theme: 'snow',
          modules: {toolbar: toolbarOptions}
        });

        // 添加中文悬停提示
        this.addChineseTitles();
        this.switchLanguage(this.currentLanguage);
      }
    });
  },
  methods: {
    async fetchCategory() {
      try {
        this.finalName = await this.getCategoryHierarchy(this.selectedNodeId);
      } catch (error) {
        console.error("Error fetching category hierarchy:", error);
      }
    },

    async getCategoryHierarchy(categoryId) {
      const subCategory = await showFaqsCategories(categoryId);
      if (subCategory && subCategory.data) {
        const subCategoryName = subCategory.data.name;
        if (subCategory.data.pid) {
          const parentCategoryName = await this.getCategoryHierarchy(subCategory.data.pid);
          return `${parentCategoryName} / ${subCategoryName}`;
        } else {
          return subCategoryName;
        }
      } else {
        throw new Error(`Category ID: ${categoryId} not found`);
      }
    },

    addLanguage() {
      let newLabel = this.languages.find(lang => lang.value === this.selectedLanguage).label;
      if (newLabel && !this.languageLabels.includes(newLabel)) {
        this.languageLabels.push(newLabel);
        this.languageContents[this.selectedLanguage] = '';
      }
      this.currentLanguage = newLabel;
      this.switchLanguage(this.currentLanguage);
    },

    removeLanguage(index) {
      const language = this.languageLabels[index];
      // 检查是否是“中文”或“English”标签
      if (language === '中文' || language === 'English') {
        this.$message.warning(`${language} ${this.$t('quickDiagnosis.cannotBeRemoved')}`);
        return;
      }
      this.languageLabels.splice(index, 1);
      delete this.languageContents[language];
      // 如果删除的是当前语言，更新当前语言为列表中的第一个（如果有的话）
      if (this.currentLanguage === language) {
        this.currentLanguage = this.languageLabels.length > 0 ? this.languageLabels[0] : '';
        this.switchLanguage(this.currentLanguage);
      }
    },
    switchLanguage(language) {
      // 保存当前语言的内容
      if (this.currentLanguage && this.quill) {
        this.languageDetails[this.currentLanguage] = {
          title: this.languageContents[this.currentLanguage] || '',
          content: this.quill.root.innerHTML || ''
        };
      }

      // 更新当前语言
      this.currentLanguage = language;

      // 确保目标语言的内容已初始化
      if (!this.languageDetails[language]) {
        this.languageDetails[language] = {title: '', content: ''};
      }

      // 加载新语言的内容到编辑器和输入框
      this.quill.root.innerHTML = this.languageDetails[language].content || '';
      this.languageContents[this.currentLanguage] = this.languageDetails[language].title || '';

    },
    cancel() {
      // 如果你给快捷诊断主页面的路由设置了名称，也可以这样做：
      this.$router.push({name: 'FAQManagement'}); // 使用命名路由
    },
    async publish() {
      // 保存当前语言内容
      if (this.currentLanguage && this.quill) {
        this.languageDetails[this.currentLanguage] = {
          title: this.languageContents[this.currentLanguage] || '',
          content: this.quill.root.innerHTML || ''
        };
      }

      // 检查必填项，包括中文和英文
      const requiredLanguages = ['中文', 'English'];
      for (const lang of requiredLanguages) {
        if (Object.prototype.hasOwnProperty.call(this.languageDetails, lang)) {
          const {title, content} = this.languageDetails[lang];
          if (!title.trim() || content.trim() === '<p><br></p>') {
            this.$message.error(this.$t('quickDiagnosis.missingRequiredFields'));
            return;
          }
        } else {
          this.$message.error(this.$t('quickDiagnosis.missingRequiredFields'));
          return;
        }
      }

      try {
        const addFaqsResponse = await addFaqs({
          // ...传递其他所需的参数
          category_id: this.selectedNodeId,
          product_type: this.selectedProductType,
          sort: 0,
          status: 1,
          no: '0',
          title: '',
          // 其他字段如 sort, status, no 如有需要继续添加
        });

        if (addFaqsResponse && addFaqsResponse.data && addFaqsResponse.data.id) {
          const faqId = addFaqsResponse.data.id;
          const contentPromises = []; // 确保这一行在 try 块的顶部

          for (const lang in this.languageDetails) {
            // 用 Object.prototype.hasOwnProperty.call 检查属性
            if (Object.prototype.hasOwnProperty.call(this.languageDetails, lang)) {
              const {title, content} = this.languageDetails[lang];
              if (title && content) {
                contentPromises.push(addFaqContents({
                  faq_id: faqId,
                  title: title.trim(),
                  lang: lang === '中文' ? 'zh' : (lang === 'English') ? 'en' : 'en',
                  content: content.trim()
                }));
              }
            }
          }

          await Promise.all(contentPromises); // 确保这一行在 try 块内
          this.$message.success(this.$t('quickDiagnosis.contentPublishedSuccess'));

          // 跳转到FAQ管理页面
          this.$router.push({name: 'FAQManagement'});
        } else {
          this.$message.error(this.$t('quickDiagnosis.faqCreationFailed'));
        }
      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.publishFailedRetry'));
      }
    },

    addChineseTitles() {
      const titles = {
        'bold': '加粗',
        'italic': '斜体',
        'underline': '下划线',
        'strike': '删除线',
        'blockquote': '引用',
        'code-block': '代码块',
        'header': '标题',
        'list': '列表',
        'script': '上标/下标',
        'indent': '缩进',
        'direction': '文本方向',
        'size': '字体大小',
        'color': '字体颜色',
        'background': '背景颜色',
        'font': '字体',
        'align': '对齐方式',
        'clean': '清除格式',
        'link': '链接',
        'image': '图片',
        'video': '视频'
      };
      document.querySelectorAll('.ql-toolbar .ql-button').forEach(btn => {
        if (btn.classList.length > 1) {
          const format = btn.classList[1].split('ql-')[1];
          if (titles[format]) {
            btn.setAttribute('title', titles[format]);
          }
        }
      });
    },
  },

};
</script>

<style scoped>

.outer-container {
  margin: 10px; /* 在div的外部四周增加10px的间距 */
}

.custom-select {
  width: 100px; /* 或您希望的任何宽度 */
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-text {
  /* 左侧文本样式 */
  font-size: 14px;
}

.language-labels {
  display: flex;
  align-items: center;

}

.language-label {
  display: flex;
  align-items: center;
  justify-content: center; /* 如果您还想水平居中，可以添加这个 */
  margin-right: 10px;
  padding: 5px;
  width: auto; /* 根据内容自动调整宽度 */
  color: green; /* 当前激活的语言标签颜色 */
  min-width: 90px; /* 设置最小宽度 */
  font-size: 14px;
  cursor: pointer; /* 添加这一行 */
}

.active-label {
  background-color: white;
  color: rgb(78, 152, 78); /* 当前展示的语言文字颜色为绿色 */
}

.inactive-label {
  background-color: rgb(250, 250, 250);
  color: rgb(37, 37, 37); /* 或您希望的任何其他默认颜色 */
}

.asterisk {
  color: red;
  margin-right: 5px;
}

.delete-button {
  color: rgb(140, 140, 140);
  margin-left: 10px;

}

.right-controls {
  display: flex;
  align-items: center;
}

.language-select {
  margin-right: 10px;
  width: 100px; /* 或者您希望的任何宽度 */
}

.ql-container {
  height: 200px;
}

/* 为新的文本框添加样式 */
.text-area {
  width: 100%; /* 设置宽度为100% */
  margin-top: 10px; /* 在文本框和上方元素之间添加一些间隔 */
  margin-bottom: 20px;
}

/* 语言标签和产品类型选择器的容器样式 */
.labels-and-selector {
  display: flex;
  justify-content: space-between; /* 在两端分布内容 */
  align-items: center; /* 垂直居中对齐 */
  margin-bottom: 10px;
}

.language-labels {
  display: flex;
  align-items: center;
}

.product-type-selector {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.required-asterisk {
  color: red;
  margin-right: 5px;
}

/* 自定义工具栏样式 */
.ql-toolbar {
  justify-content: left; /* 工具栏靠左对齐 */
}

/* 为工具栏按钮添加悬停效果 */
.ql-toolbar .ql-button:hover {
  background-color: #f2f2f2; /* 鼠标悬停时的背景色 */
}

</style>
