<template>

  <div class="search-box">
    <el-form label-position="top">
      <el-row :gutter="20">
        <el-col :span="24" :md="12" :lg="4">

          <el-form-item class="form-item-custom" :label=" $t('quickDiagnosis.status')">
            <el-select v-model="searchForm.status" :placeholder="$t('quickDiagnosis.pleaseSelectStatus')" filterable
                       style="width: 100%"
                       clearable>
              <el-option :label=" $t('quickDiagnosis.published')" value="1"></el-option>
              <el-option :label=" $t('quickDiagnosis.unpublished')" value="0"></el-option>
            </el-select>
          </el-form-item>

        </el-col>

        <el-col :span="24" :md="12" :lg="6">

          <el-form-item :label=" $t('quickDiagnosis.submissionTime')">
            <el-date-picker v-model="searchForm.submitTimeRange" type="datetimerange" :clearable="true"
                            :start-placeholder="$t('quickDiagnosis.startDate')"
                            :end-placeholder="$t('quickDiagnosis.endDate')"/>
          </el-form-item>

        </el-col>

        <el-col :span="24" :md="12" :lg="6">

          <el-form-item :label=" $t('quickDiagnosis.fuzzySearchKeyword')" >
            <el-input v-model="searchForm.keyword" :placeholder="$t('quickDiagnosis.pleaseEnterSearchKeyword')" clearable>
              <template #suffix>
                <el-icon style="color: black;">
                  <search/>
                </el-icon>
              </template>
            </el-input>
          </el-form-item>

        </el-col>

        <el-col :span="24" :md="12" :lg="8">

          <el-form-item class="form-item-button" label="&nbsp;">
            <el-button class="button-background-color" @click="search">{{ $t('quickDiagnosis.search') }}</el-button>
            <el-button @click="reset" class="custom-reset-button">{{ $t('quickDiagnosis.reset') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>

</template>

<script>

import {Search} from "@element-plus/icons-vue";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  components: {Search},
  data() {
    return {
      searchForm: {
        language: "", // 语言选择
        status: "", // 状态选择
        submitTimeRange: [], // 提交时间范围
        keyword: "", // 模糊搜索关键字
      },
    };
  },
  mixins: [dialogMixin],
  methods: {
    search() {
      let searchCondition = {};

      if (this.searchForm.submitTimeRange && this.searchForm.submitTimeRange.length === 2) {
        const [start, end] = this.searchForm.submitTimeRange;
        searchCondition["updated_at-begin"] = this.formatDate(null, null, start); // 或者格式化为其他后端期望的格式
        searchCondition["updated_at-end"] = this.formatDate(null, null, end); // 或者格式化为其他后端期望的格式
      }

      if (this.searchForm.keyword) {
        searchCondition["name"] = this.searchForm.keyword;
        searchCondition["lang"] = this.$i18n.locale;
      }

      if (this.searchForm.status) {
        searchCondition["status-eq"] = this.searchForm.status;
      }

      this.$emit("search", searchCondition);

    },
    reset() {
      this.searchForm = {
        status: "", // 状态选择
        submitTimeRange: [], // 提交时间范围
        keyword: "", // 模糊搜索关键字
      }
      this.search();
    },
  },
};
</script>

<style scoped>

.search-box .el-row {
  margin: 0; /* 缩小 el-row 的边距 */
}

.search-box .el-col {
  padding: 0 10px; /* 为每个 el-col 设置内边距 */
}

.search-box .el-form-item {
  margin-bottom: 10px; /* 减小 el-form-item 的底部边距 */
}

/* 为包含表单的外层容器添加边框 */
.search-box {
  border: 1px solid rgba(221, 221, 221, 0.6); /* 设置边框样式 */
  padding: 15px; /* 添加一些内边距 */
  border-radius: 5px; /* 圆角边框 */
}


</style>
