<template>

  <div class="history-search-bar">
    <el-form :model="searchForm" class="search-form" label-position="top">
      <el-row :gutter="20">

        <el-col :span="9" :md="9" :lg="9">
          <el-form-item :label="$t('HistoryData.diagnosisReport.timeOfDiagnosis')">
            <el-date-picker v-model="searchForm.diagnosticDate" type="datetimerange" :clearable="true"
                            :start-placeholder="$t('HistoryData.diagnosisReport.startDate')"
                            :popper-options="{
   modifiers: [
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['bottom'],
                allowedAutoPlacements: ['bottom'],
            }
        }
    ]
}"
                            :end-placeholder="$t('HistoryData.diagnosisReport.endDate')"/>
          </el-form-item>
        </el-col>

        <el-col :span="5" :md="5" :lg="5">
          <el-form-item :label="$t('HistoryData.diagnosisReport.thePersonIn')">
            <el-input v-model="searchForm.diagnosticPerson" ref="diagnosticPersonInput"
                      :placeholder="$t('HistoryData.diagnosisReport.pleaseEnter')"
                      :clearable="true"/>
          </el-form-item>
        </el-col>

        <el-col :span="5" :md="5" :lg="5">
          <el-form-item label-position="top" :label="$t('HistoryData.diagnosisReport.diagnosisOrganization')">
            <el-tree-select
                v-model="searchForm.diagnosticOrganization"
                :data="organizationOptions"
                :props="{ label: 'label', children: 'children' }"
                check-strictly
                :placeholder="$t('HistoryData.diagnosisReport.pleaseSelect')"
                :render-after-expand="false"
                clearable filterable
                class="custom-tree-select-width"
            />

          </el-form-item>
        </el-col>

        <el-col :span="5" :md="5" :lg="5">

          <el-form-item label="&nbsp;">
            <el-button class="button-background-color" @click="search">
              {{ $t('HistoryData.diagnosisReport.search') }}
            </el-button>
            <el-button @click="reset" class="custom-reset-button">
              {{ $t('HistoryData.diagnosisReport.reset') }}
            </el-button>
          </el-form-item>

        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {getOrganizationsData} from "@/api/api";
import {listToTree} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  data() {
    return {
      searchForm: {
        diagnosticPerson: '',
        diagnosticOrganization: '',
        diagnosticDate: [],
      },
      diagnosticOrganization: [],
      organizationOptions: [],
    };
  },
  mixins: [dialogMixin],
  mounted() {
    this.fetchOrganizationOptions();
  },

  methods: {

    search() {

      let searchCondition = {};

      if (this.searchForm.diagnosticDate && this.searchForm.diagnosticDate.length === 2) {
        const [start, end] = this.searchForm.diagnosticDate;
        searchCondition["created_at-begin"] = this.formatDate(null, null, start); // 或者格式化为其他后端期望的格式
        searchCondition["created_at-end"] = this.formatDate(null, null, end); // 或者格式化为其他后端期望的格式
      }

      if (this.searchForm.diagnosticPerson) {
        searchCondition["user-name-like"] = this.searchForm.diagnosticPerson;
      }

      if (this.searchForm.diagnosticOrganization) {
        searchCondition["organization_id-eq"] = this.searchForm.diagnosticOrganization;
      }

      this.$emit("search", searchCondition);
    },

    reset() {
      this.searchForm = {
        diagnosticPerson: '',
        diagnosticOrganization: '',
        diagnosticDate: [],
      };
      this.search();
    },

    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.fetchOrganizationFailed'));
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    },


  },
};
</script>

<style scoped>

</style>
