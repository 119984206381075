<template>
  <div class="table-component" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{ background: 'rgb(250, 250, 250)' }" :data="faqData" style="width: 100%"
              class="table-height"
              :row-style="{ height: '57px' }" :border="false" row-key="id">
      <el-table-column prop="sorting" :label="$t('quickDiagnosis.sorting')"></el-table-column>
      <el-table-column prop="faultCategory" :label="$t('quickDiagnosis.faultCategory')"></el-table-column>
      <el-table-column prop="faultDescription"
                       :label="$t('quickDiagnosis.faultDescriptionFaultCode')"></el-table-column>
      <el-table-column prop="productModel" :label="$t('quickDiagnosis.productType')" :formatter="formatProductType">
      </el-table-column>
      <el-table-column prop="number" :label="$t('quickDiagnosis.number')"></el-table-column>
      <el-table-column prop="updateTime" :label="$t('quickDiagnosis.updateTime')"
                       :formatter="formatDate"></el-table-column>

      <el-table-column :label="$t('quickDiagnosis.operation')">

        <template v-slot:default="scope">
          <div v-if="$route.meta.fromTopMenu" @click="viewDetails(scope.row)">
            <el-button link><span class="link-text-style">{{ $t('quickDiagnosis.view') }}</span></el-button>
          </div>
          <template v-else>
            <el-popover placement="left-start" trigger="hover" popper-style="min-width: 100px;" width="80px">
              <template #reference>
                <el-button link><span class="link-text-style">{{ $t('quickDiagnosis.operation') }}</span></el-button>
              </template>
              <template #default>

                <div @click="editFAQ(scope.row.id)" class="action-item">
                  <el-icon>
                    <EditPen/>
                  </el-icon>
                  <el-button link>{{ $t('quickDiagnosis.edit') }}</el-button>
                </div>

                <div @click="transferFaq(scope.row)" class="action-item">
                  <el-icon>
                    <Back/>
                  </el-icon>
                  <el-button link>{{ $t('quickDiagnosis.transfer') }}</el-button>
                </div>
                <div @click="invalidateFaq(scope.row)" class="action-item">
                  <el-icon>
                    <DocumentDelete/>
                  </el-icon>
                  <el-button link>
                    {{ scope.row.status === 0 ? $t('quickDiagnosis.publishNoSpace') : $t('quickDiagnosis.invalid') }}
                  </el-button>
                </div>
                <div @click="sortFaq(scope.row)" class="action-item">
                  <el-icon>
                    <Operation/>
                  </el-icon>
                  <el-button link>{{ $t('quickDiagnosis.sorting') }}</el-button>
                </div>
                <div @click="deleteFaq(scope.row)" class="action-item">
                  <el-icon>
                    <Delete/>
                  </el-icon>
                  <el-button link>{{ $t('quickDiagnosis.delete') }}</el-button>
                </div>
                <div @click="viewDetails(scope.row)" class="action-item">
                  <el-icon>
                    <Document/>
                  </el-icon>
                  <el-button link>{{ $t('quickDiagnosis.details') }}</el-button>
                </div>
              </template>
            </el-popover>
          </template>
        </template>
      </el-table-column>

    </el-table>
  </div>

  <!-- 点击转移的弹框 -->
  <el-dialog v-model="transferFaqDialogVisible" width="500" style="text-align: left;"
             :title="$t('quickDiagnosis.transferFAQ')">
    <hr class="top-separator"/>
    <el-form :model="transferFaqForm" label-position="top">
      <el-form-item :label="$t('quickDiagnosis.directory')" prop="directory">
        <el-tree-select v-model="transferFaqForm.directory" check-strictly :data="treeData"
                        :placeholder="$t('quickDiagnosis.selectDirectory')" style="width: 300px;" clearable filterable/>
      </el-form-item>
    </el-form>

    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="cancelTransferFaq" class="custom-reset-button">{{ $t('quickDiagnosis.cancel') }}</el-button>
      <el-button class="button-background-color" @click="confirmTransferFaq">{{ $t('quickDiagnosis.confirm') }}
      </el-button>
    </div>
  </el-dialog>

  <!-- 点击排序的弹框 -->
  <el-dialog v-model="sortFaqDialogVisible" width="500" style="text-align: left;" @open="disableScroll"
             @close="enableScroll"
             :title="$t('quickDiagnosis.editOrganization')">
    <hr class="top-separator"/>

    <el-form :model="sortFaqForm" :rules="sortFaqFormRules" label-position="top">
      <el-form-item :label="$t('quickDiagnosis.sequenceNumber')" prop="number">
        <el-input-number v-model="sortFaqForm.number" :min="-Infinity" :step="1" style="width: 300px;"
                         :placeholder="$t('quickDiagnosis.enterSequenceNumber')" controls-position="right"
                         clearable></el-input-number>
      </el-form-item>
    </el-form>
    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="cancelSortFaq" class="custom-reset-button">{{ $t('quickDiagnosis.cancel') }}</el-button>
      <el-button class="button-background-color" @click="confirmSortFaq">{{ $t('quickDiagnosis.confirm') }}</el-button>
    </div>
  </el-dialog>

  <!-- 点击删除的弹框 -->
  <DeleteDialog
      :visible="deleteFaqDialogVisible"
      :message="$t('quickDiagnosis.confirmDeleteMessage')"
      @confirm="confirmDeleteFaq"
      @cancel="cancelDeleteFaq"
      @update:visible="deleteFaqDialogVisible = $event"
  ></DeleteDialog>

</template>

<script>
import {Back, Delete, Document, DocumentDelete, EditPen, Operation} from "@element-plus/icons-vue";
import resizeMixin from '@/mixins/resizeMixin';
import {deleteFaqs, getFaqCategories, updateFaqs} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import DeleteDialog from "@/components/DeleteDialog.vue";

export default {
  mixins: [resizeMixin, dialogMixin],
  components: {DeleteDialog, Document, Delete, Operation, DocumentDelete, Back, EditPen},
  props: {
    faqData: Array,
    loadData: {
      type: Function,
      required: true,
    },
    loading: Boolean,  // 接收 loading 状态
    selectedNodeId: {
      type: [String, Number], // 依据实际情况这里可能只是String或只是Number
      default: null
    },
  },
  data() {
    return {
      deleteFaqDialogVisible: false,
      sortFaqDialogVisible: false,
      transferFaqDialogVisible: false,
      sortFaqForm: {
        number: '',
      },
      sortFaqFormRules: {
        number: [
          {
            required: true, message: this.$t('quickDiagnosis.enterSequenceNumber'), trigger: 'blur',
          }
        ],
      },
      transferFaqForm: {
        directory: '',
      },
      selectedFaqId: null, // 存储当前选中的FAQ ID
      treeData: [],
    }
  },

  mounted() {
    this.fetchTreeData();
  },
  methods: {
    formatProductType(row, column, value) {
      return value === 0 ? this.$t('quickDiagnosis.midMount') : this.$t('quickDiagnosis.hub');
    },
    // 点击详情的方法
    viewDetails(row) {
      // 假设你有一个名为 'Details' 的路由，并且你希望传递故障代码的 ID
      this.$router.push({name: 'Details', query: {id: row.id}});
    },
    editFAQ(rowId) {
      console.log(12344474977)
      console.log(rowId)
      console.log(this.selectedNodeId)
      console.log(12344474977)
      // 假设你有一个名为 'Details' 的路由，并且你希望传递故障代码的 ID
      //this.$router.push({name: 'EditFAQ', params: {id: rowId,selectedNodeId: this.selectedNodeId}});
      this.$router.push({
        name: 'EditFAQ',
        params: { id: rowId }, // 继续传递 id
        query: { selectedNodeId: this.selectedNodeId } // 使用 query 传递 selectedNodeId
      });


    },
    //点击删除的方法
    deleteFaq(row) {
      this.selectedFaqId = row.id; // 存储要删除的FAQ的ID
      this.deleteFaqDialogVisible = true;
    },
    cancelDeleteFaq() {
      this.deleteFaqDialogVisible = false;
    },
    async confirmDeleteFaq() {
      try {
        await deleteFaqs(this.selectedFaqId); // 调用API删除目录
        this.$message.success(this.$t('quickDiagnosis.faqDeletedSuccessfully'));

        this.deleteFaqDialogVisible = false; // 关闭对话框

        await this.loadData(); // 重新获取目录数据，更新视图

      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.faqDeletionFailed'));
      }
    },

    async invalidateFaq(row) {
      this.selectedFaqId = row.id;
      const formData = {
        status: row.status === 0 ? 1 : 0, // 如果当前状态是0（发布），则设置为1（失效），否则设置为0
      };

      if (this.selectedFaqId && formData) {
        try {
          const response = await updateFaqs(this.selectedFaqId, formData);
          if (response.code === -1) {
            this.$message.error(this.$t('quickDiagnosis.statusUpdateFailed'));
          } else if (response.code === 0) {
            this.$message.success(this.$t('quickDiagnosis.statusUpdatedSuccessfully'));
            await this.loadData(); //重新获取并刷新FAQ列表
          } else {
            this.$message.error(this.$t('quickDiagnosis.statusUpdateFailed'));
          }
        } catch (error) {
          this.$message.error(this.$t('quickDiagnosis.statusUpdateFailed'));
        }
      } else {
        this.$message.error(this.$t('quickDiagnosis.pleaseRetry'));
      }
    },
    sortFaq(row) {
      this.selectedFaqId = row.id;
      this.sortFaqForm.number = row.sorting;
      this.sortFaqDialogVisible = true;
    },
    cancelSortFaq() {
      this.sortFaqDialogVisible = false;
    },

    async confirmSortFaq() {
      const formData = {
        sort: this.sortFaqForm.number,
      };
      if (this.selectedFaqId && formData) {
        try {
          const response = await updateFaqs(this.selectedFaqId, formData);
          if (response.code === -1) {
            this.$message.error(this.$t('quickDiagnosis.sortUpdateFailed'));
          } else if (response.code === 0) {
            this.$message.success(this.$t('quickDiagnosis.sortUpdatedSuccessfully'));
            this.sortFaqDialogVisible = false; // 关闭排序对话框
            await this.loadData(); // 假设你有一个方法来重新获取并刷新FAQ列表
          } else {
            this.$message.error(this.$t('quickDiagnosis.sortUpdateFailed'));
          }
        } catch (error) {
          this.$message.error(this.$t('quickDiagnosis.sortUpdateFailed'));
        }
      } else {
        this.$message.error(this.$t('quickDiagnosis.ensureValidSortNumber'));
      }
    },
    transferFaq(row) {
      this.selectedFaqId = row.id;
      this.transferFaqForm.directory = row.faultCategory;
      this.transferFaqDialogVisible = true;
    },
    cancelTransferFaq() {
      this.transferFaqDialogVisible = false;
    },

    async confirmTransferFaq() {
      const formData = {
        category_id: this.transferFaqForm.directory,
      };

      if (this.selectedFaqId && formData) {
        try {
          const response = await updateFaqs(this.selectedFaqId, formData);

          // 在这里检查响应代码
          if (response.code === -1) {
            // 显示转移失败信息
            this.$message.error(this.$t('quickDiagnosis.transferFailed'));
          } else {
            // 成功的情况
            this.$message.success(this.$t('quickDiagnosis.transferSuccessful'));
            this.transferFaqDialogVisible = false; // 关闭对话框
            await this.loadData(); // 刷新数据
          }
        } catch (error) {
          // 处理API调用错误
          this.$message.error(this.$t('quickDiagnosis.transferFailed'));
        }
      } else {
        // 验证失败
        this.$message.error(this.$t('quickDiagnosis.transferFailed'));
      }
    },


    async fetchTreeData() {
      if (this.searchKeyword) return; // 如果当前有搜索关键字，不重新加载数据
      try {
        const response = await getFaqCategories();
        this.treeData = this.buildTreeStructure(response.data);
      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.fetchingDataFailed'));
      }
    },
    buildTreeStructure(data) {
      let map = {}, roots = [];

      data.forEach(item => {
        // 假设 pid 为 null 或特定值时表示顶级节点
        let isTopLevel = !item.pid || item.pid === 0;
        map[item.id] = {...item, children: [], isTopLevel: isTopLevel};
      });

      // 根据pid构建树形结构
      data.forEach(item => {
        let parent = map[item.pid];
        if (parent) {
          // 如果找到父节点，将当前节点添加到父节点的children数组
          parent.children.push(map[item.id]);
        } else {
          // 如果没有找到父节点，说明是根节点
          roots.push(map[item.id]);
        }
      });
      // 将树形结构转换为需要的格式
      return roots.map(root => this.processNode(root));
    },

    processNode(node) {
      // 转换每个节点到期望的格式，这里可以根据需要添加或修改属性
      return {
        key: node.id.toString(), // 确保key是字符串或数字
        label: node.name,
        value: node.id,
        showDeleteIcon: false,
        isTopLevel: node.isTopLevel, // 确保保留isTopLevel属性
        children: node.children.map(child => this.processNode(child))
      };
    },
  },
};
</script>

<style scoped>
.warning-message {
  text-align: center;
  /* 居中整个内容 */
  display: flex;
  /* 使用 flex 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  margin-bottom: 30px;
}

.warning-icon {
  font-size: 24px;
  /* 减小图标大小 */
  color: #f1c40f;
  margin-right: 10px;
  /* 在图标和文本之间添加一些间距 */
}

.warning-text {
  color: black;
  font-size: 16px;
}

:deep( .el-form-item__label ) {
  margin-bottom: 0;
  /* 减少label下方的外边距 */
  padding-bottom: 0;
  /* 减少label的内边距 */
}

/* 样式 */
.table-component {
  margin-top: 20px;
}

.action-item {
  margin-bottom: 5px;
  /* 调整底部间距 */
}
</style>