<template>
  <div class="history-search-bar">
    <el-form :model="searchForm" label-position="top">
      <el-row :gutter="20">
        <el-col :span="24" :sm="4" :md="12" :lg="6">
          <el-form-item :label="$t('HistoryData.CustomerFeedback.contacts')">
            <el-input v-model="searchForm.contactName"
                      :placeholder="$t('HistoryData.CustomerFeedback.contactsPlaceholder')"
                      :clearable="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="4" :md="12" :lg="6">
          <el-form-item :label="$t('HistoryData.CustomerFeedback.phoneNumber')">
            <el-input v-model="searchForm.contactPhone"
                      :placeholder="$t('HistoryData.CustomerFeedback.phoneNumberPlaceholder')"
                      :clearable="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="8" :md="12" :lg="8" :xs="24">
          <el-form-item :label="$t('HistoryData.CustomerFeedback.timeOfFeedback')">
            <el-date-picker v-model="searchForm.feedbackTime" type="datetimerange" :clearable="true"
                            :start-placeholder="$t('HistoryData.CustomerFeedback.timeOfFeedbackPlaceholder')"
                            :popper-options="{
   modifiers: [
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['bottom'],
                allowedAutoPlacements: ['bottom'],
            }
        }
    ]
}"
                            :end-placeholder="$t('HistoryData.CustomerFeedback.endDatePlaceholder')"/>
          </el-form-item>
        </el-col>
        <el-col :span="24" :sm="6" :md="12" :lg="4">
          <el-form-item label="&nbsp;">
            <el-button class="button-background-color" @click="search"> {{
                $t('HistoryData.CustomerFeedback.search')
              }}
            </el-button>
            <el-button @click="reset" class="custom-reset-button"> {{
                $t('HistoryData.CustomerFeedback.reset')
              }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";

export default {
  data() {
    return {
      searchForm: {
        contactName: '',
        contactPhone: '',
        feedbackTime: '',
      },
    };
  },
  mixins: [dialogMixin],
  methods: {
    search() {

      let searchCondition = {};

      if (this.searchForm.feedbackTime && this.searchForm.feedbackTime.length === 2) {
        const [start, end] = this.searchForm.feedbackTime;
        searchCondition["created_at-begin"] = this.formatDate(null, null, start); // 或者格式化为其他后端期望的格式
        searchCondition["created_at-end"] = this.formatDate(null, null, end); // 或者格式化为其他后端期望的格式
      }

      if (this.searchForm.contactName) {
        searchCondition["contact-like"] = this.searchForm.contactName;
      }

      if (this.searchForm.contactPhone) {
        searchCondition["phone-like"] = this.searchForm.contactPhone;
      }

      this.$emit('search', searchCondition);
    },
    reset() {
      this.searchForm = {
        contactName: '',
        contactPhone: '',
        feedbackTime: '',
      }
      this.search();
    },
  },
};
</script>
<style scoped>

</style>
  