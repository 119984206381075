<template>
  <div class="details-container">
    <div class="header">
      <div>
        <span>{{ detail.code }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;<span>{{ detail.date }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;<span v-if="!$route.meta.hideBackButton">{{ detail.category }}</span>
      </div>
      <el-button class="custom-reset-button" @click="goBack" v-if="!$route.meta.hideBackButton">{{ $t('quickDiagnosis.return') }}</el-button>
    </div>
    <div class="error-code">{{ detail.errorCode }}</div>
    <div class="content">
      <div class="content-body">
        <h2 style="padding-bottom: 10px; border-bottom: 1px solid rgb(230, 233, 234);">{{ detail.title }}</h2>
        <!-- 假设detail.steps是一个字符串，我们使用v-html进行渲染 -->
        <div v-html="detail.steps"></div>
      </div>

    </div>
  </div>
</template>

<script>
import {showFaqData} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [dialogMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // 初始化detail对象
      detail: {
        code: '',
        date: '',
        category: '',
        errorCode: '',
        title: '',
        steps: []
      }
    };
  },
  watch: {
    '$i18n.locale'() {
      const id = this.id;
      this.fetchDetail(id);
    }
  },
  created() {
    // 从路由参数获取id并获取详情
    const id = this.id;
    this.fetchDetail(id);
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async fetchDetail(id) {
      try {
        const faqDataResponse = await showFaqData(id);

        // 获取当前语言
        const currentLang = this.$i18n.locale === 'zh' ? 'zh' : (this.$i18n.locale === 'en' ? 'en' : 'en');

        // 查找与当前语言匹配的内容
        let content = faqDataResponse.data.contents.find(item => item.lang === currentLang);

        // 如果未找到当前语言的内容，默认使用英语
        if (!content) {
          content = faqDataResponse.data.contents.find(item => item.lang === 'en');
        }
        console.log(111827373777)
        console.log(faqDataResponse)
        console.log(content)
        console.log(currentLang)
        console.log(111827373777)
        // 根据产品类型值转换为中文
        const productType = faqDataResponse.data.product_type === 1 ? this.$t('quickDiagnosis.hubDrive') : this.$t('quickDiagnosis.midDrive');
        this.detail = {
          code: faqDataResponse.data.no,
          date: this.formatDate(null, null, faqDataResponse.data.created_at),
          category: productType, // 使用转换后的中文值
          errorCode: faqDataResponse.data.category_name,
          title: content.title,
          steps: content.content // 确保这是数组或者适合你的数据结构
        };


      } catch (error) {
        console.error(error, this.$t('quickDiagnosis.fetchDetailDataFailed'));
      }
    },
  }
};
</script>

<style scoped>
.details-container {
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between; /* 保持返回按钮在右侧 */
  align-items: center;
  margin-bottom: 16px;
}

.error-code {
  text-align: left; /* 保证错误代码文本左对齐 */
  margin-bottom: 16px; /* 添加一些空间在错误代码下方 */
}

.content {
  text-align: left; /* 保证内容文本左对齐 */
}

.content h2 {
  color: black; /* 标题颜色 */
  margin-bottom: 4px; /* 标题与横线的间距 */
}

hr {
  border: none;
  height: 1px;
  background-color: #ccc; /* 横线颜色 */
  margin-bottom: 16px; /* 横线下方的间距 */
}

.content-body p {
  margin: 4px 0; /* 段落之间的间距 */
}

/* 如果您的.content-body是flex布局并且居中，需要修改为以下样式 */
.content-body {
  display: block; /* 或者保持flex布局但是改变对齐方式 */
}
</style>

