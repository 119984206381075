<template>
  <hr class="separator " style="margin-top: 1px" />

  <div>

    <div class="custom-text">
      <span class="user-manage">{{ $t('HistoryData.CustomerFeedback.historyData') }} &nbsp;/&nbsp;</span>
      {{ $t('HistoryData.CustomerFeedback.customerFeedback') }}
    </div>
    <hr class="separator" />

  </div>
  <div class="feedback-page">
    <!-- 使用 SearchBar 组件 -->
    <SearchBar @search="searchFeedback" />

    <!-- 使用 DataTable 组件 -->
    <DataTable :feedbackData="feedbackData" :loading="loading"/>

    <!-- 使用 PaginationBar 组件 -->
    <div class="pagination">
      <PaginationBar :currentPage="currentPage" :pageSize="pageSize" :totalRecords="totalRecords"
        @page-change="handlePageChange" @page-size-change="handlePageSizeChange" />
    </div>
  </div>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import DataTable from './components/DataTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import {getCustomerFeedback} from "@/api/api";

export default {
  components: {
    SearchBar,
    DataTable,
    PaginationBar,
  },
  data() {
    return {
      feedbackData: [], // 反馈数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      totalRecords: 0, // 总记录数
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async searchFeedback(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        // 使用 await 等待异步函数的结果
        const response = await getCustomerFeedback(page, pageSize, searchParams);

        // 进行数据转换
        this.feedbackData = response.data.map(item => ({
          contactName: item.contact,
          contactPhone: item.phone,
          remark: item.remark,
          feedbackTime: item.created_at,
          file_url: item.file_url,
          // 其他字段映射
        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);
      } catch (error) {
        // 使用 try/catch 捕获异步操作中的错误
        console.error(this.$t('ProductManage.searchFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },
    handlePageChange(newPage) {
      // 处理页码变化
      this.currentPage = newPage;
      this.searchFeedback(); // 重新获取数据
    },
    handlePageSizeChange(newSize) {
      // 处理每页显示条数变化
      this.pageSize = newSize;
      this.searchFeedback(); // 重新获取数据
    },
    async loadData() {
      this.isSearching = true; // 假设有一个isSearching状态用于显示加载中的UI
      this.loading = true; // 开始加载
      try {
        // 直接使用组件的状态（currentPage和pageSize）作为分页参数
        const page = this.currentPage;
        const pageSize = this.pageSize;

        // 从getDiagnoseReport获取数据，并传递分页和查询参数
        const data = await getCustomerFeedback(page, pageSize);

        if (!Array.isArray(data.data)) {
          throw new Error('Expected an array of data');
        }

        // 转换数据格式，根据实际返回的数据结构调整字段名
        this.feedbackData = data.data.map(item => ({
          contactName: item.contact,
          contactPhone: item.phone,
          remark: item.remark,
          feedbackTime: item.created_at,
          file_url: item.file_url,
        })); // 更新表格数据
        this.totalRecords = data.meta.total; // 更新总记录数
        this.currentPage = data.meta.current_page; // 更新当前页（如果后端返回当前页信息）
        this.pageSize = parseInt(data.meta.per_page, 10); // 更新每页记录数

      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      } finally {
        this.isSearching = false; // 加载完成，无论成功或失败
        this.loading = false; // 加载结束
      }
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/publicStyle.css";
</style>