<template>
  <div class="tree-node-actions" :class="{ 'node-selected': isSelected }">
    <span class="node-label" :title="nodeData.label">{{ nodeData.label }}</span>
    <el-popover
        placement="right-start"
        trigger="hover"
        :offset="1"
        popper-class="node-actions-popover custom-popover-width"
    >
      <!-- 使用div包裹每个按钮，确保它们各自占据一行 -->
      <div class="popover-action-item left-align-buttons">
        <el-button class="left-align-content" link @click="editNode(nodeData)"
                   size="small"
                   @mouseover="hoverStates.edit = true"
                   @mouseleave="hoverStates.edit = false"
                   :style="buttonStyle('edit')"
        >
          <el-icon class="icon-spacing">
            <EditPen/>
          </el-icon>
          {{ $t('quickDiagnosis.edit') }}
        </el-button>
      </div>
      <div class="popover-action-item left-align-buttons">
        <el-button link @click="deleteNode(nodeData)"
                   size="small"
                   @mouseover="hoverStates.delete = true"
                   @mouseleave="hoverStates.delete = false"
                   :style="buttonStyle('delete')">
          <el-icon class="icon-spacing">
            <Delete/>
          </el-icon>
          {{ $t('quickDiagnosis.delete') }}
        </el-button>
      </div>
      <div class="popover-action-item left-align-buttons" v-if="shouldShowAddButton(nodeData)">
        <el-button link @click="addNode(nodeData)"
                   size="small"
                   @mouseover="hoverStates.add = true"
                   @mouseleave="hoverStates.add = false"
                   :style="buttonStyle('add')">
          <el-icon class="icon-spacing">
            <Plus/>
          </el-icon>
          {{ $t('quickDiagnosis.addDirectory') }}
        </el-button>
      </div>
      <template v-slot:reference>
        <el-icon class="rotate-icon">
          <More/>
        </el-icon>
      </template>
    </el-popover>
  </div>

  <!-- 编辑弹框 -->
  <el-dialog style="text-align: left;"
             :title="$t('quickDiagnosis.editDirectory')"
             v-model="editDialogVisible"
             width="20%"
             @open="disableScroll" @close="handleDialogClose"
  >
    <hr class="top-separator"/>
    <el-form ref="editForm" :model="editFormData" label-width="100px" :rules="editFormDataRules" label-position="top">
      <!-- 目录名称 -->
      <el-form-item :label="$t('quickDiagnosis.directoryName')" prop="directoryName">
        <el-input
            class="narrow-input"
            v-model="editFormData.directoryName"
            :rows="2"
            type="textarea"
            maxlength="50"
            show-word-limit
            :placeholder=" $t('quickDiagnosis.pleaseEnterDirectoryName')"
            clearable
        />

      </el-form-item>
    </el-form>
    <hr class="dialog-separator"/>
    <div class="dialog-footer">
      <el-button @click="closeEditDialog" class="custom-reset-button">{{ $t('quickDiagnosis.cancel') }}</el-button>
      <el-button class="button-background-color" @click="editDirectoryData">{{
          $t('quickDiagnosis.confirm')
        }}
      </el-button>
    </div>
  </el-dialog>

  <!-- 新增弹框 -->
  <el-dialog style="text-align: left;"
             :title="$t('quickDiagnosis.addDirectory')"
             v-model="addDialogVisible"
             width="20%"
             @closed="resetForm" @open="disableScroll" @close="enableScroll"
  >
    <hr class="top-separator"/>
    <el-form ref="addForm" :model="formData" label-width="100px" :rules="formDataRules" label-position="top">
      <!-- 目录名称 -->
      <el-form-item :label="$t('quickDiagnosis.directoryName')" prop="directoryName">
        <el-input
            v-model="formData.directoryName"
            class="narrow-input"
            :rows="2"
            type="textarea"
            maxlength="50"
            show-word-limit
            :placeholder=" $t('quickDiagnosis.pleaseEnterDirectoryName')"
        />
      </el-form-item>
    </el-form>
    <hr class="dialog-separator"/>
    <div class="dialog-footer">
      <el-button @click="addDialogVisible = false" class="custom-reset-button">{{
          $t('quickDiagnosis.cancel')
        }}
      </el-button>
      <el-button class="button-background-color" @click="addDirectoryData">{{
          $t('quickDiagnosis.confirm')
        }}
      </el-button>
    </div>
  </el-dialog>

  <!-- 删除弹框 -->
  <DeleteDialog
      :visible="deleteDialogVisible"
      :message="$t('quickDiagnosis.deleteConfirmation')"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
      @update:visible="deleteDialogVisible = $event"
  ></DeleteDialog>

</template>


<script>
import {Delete, EditPen, More, Plus} from "@element-plus/icons-vue";
import {addFaqCategories, deleteFaqCategories, showFaqsCategories, updateFaqCategories} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import DeleteDialog from "@/components/DeleteDialog.vue";

export default {
  components: {DeleteDialog, Plus, Delete, EditPen, More},
  mixins: [dialogMixin],
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      deleteDialogVisible: false,
      hoverStates: {
        edit: false,
        delete: false,
        add: false,
      },
      formData: {
        directoryName: '', // 确保这个属性与 form item 的 prop 相匹配
      },
      editFormData: {
        directoryName: '', // 确保这个属性与 form item 的 prop 相匹配
      },
      formDataRules: {
        directoryName: [
          {required: true, message: this.$t('quickDiagnosis.pleaseEnterDirectoryName'), trigger: 'blur'},
        ],
      },
      editFormDataRules: {
        directoryName: [
          {required: true, message: this.$t('quickDiagnosis.pleaseEnterDirectoryName'), trigger: 'blur'},
        ],
      },
      currentNode: null, // 添加这行来初始化 currentNode
    }
  },
  props: {
    nodeData: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    fetchTreeData: {
      type: Function,
      required: true
    }
  },

  computed: {
    buttonStyle() {
      return (type) => {
        if (this.hoverStates[type]) {
          return {
            width: '80px', // 设置统一宽度
            color: 'rgb(78,152,78)',
            borderColor: 'rgb(78,152,78)',
          };
        } else {
          return {
            color: 'black',
            borderColor: 'transparent',
          };
        }
      };
    },
  },
  methods: {
    cancelDelete() {
      this.deleteDialogVisible = false;
    },
    handleDialogClose() {
      this.resetEditForm();
      this.enableScroll();
    },
    async confirmDelete() {

      if (this.currentNode && this.currentNode.key) {
        try {
          await deleteFaqCategories(this.currentNode.key); // 调用API删除目录
          this.$message.success(this.$t('quickDiagnosis.directoryDeletedSuccessfully'));
          this.deleteDialogVisible = false; // 关闭对话框
          await this.fetchTreeData(); // 重新获取目录数据，更新视图
        } catch (error) {
          this.$message.error(this.$t('quickDiagnosis.directoryDeleteFailed'));
        }
      }
    },

    async addDirectoryData() {
      if (!this.currentNode) {
        return;
      }
      try {
        // 并且假设它接受一个对象，包含你想要创建的目录的信息
        await addFaqCategories({
          name: this.formData.directoryName,
          pid: this.currentNode.key, // 使用 currentNode 的 id 作为 pid
          level: this.currentNode.key, // 假设子目录的级别是当前节点级别 + 1
          // 你可能还需要其他字段，根据你的后端API要求添加
        });
        this.$message.success(this.$t('quickDiagnosis.directoryCreatedSuccessfully'));
        this.addDialogVisible = false; // 关闭对话框
        await this.fetchTreeData(); // 重新获取目录数据，以显示新添加的目录
      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.createDirectoryFailed'));
      }
    },

    async editNode(data) {
      this.currentNode = data;

      this.editDialogVisible = true;

      try {
        const faqId = data.key;
        const faqInformation = await showFaqsCategories(faqId);
        this.editFormData.directoryName = faqInformation.data.name;
      } catch (error) {
        this.$message.error(this.$t('quickDiagnosis.editNodeDataFailed'));
      }
    },
    deleteNode(data) {
      this.currentNode = data; // 保存当前节点信
      this.deleteDialogVisible = true;
    },
    addNode(data) {
      this.currentNode = data;
      this.addDialogVisible = true;
    },
    closeEditDialog() {
      this.editDialogVisible = false;
    },
    async editDirectoryData() {
      // 验证 formData 是否有效
      await this.$refs.editForm.validate(async (valid) => {
        if (!valid) {
          return; // 表单数据无效时停止执行
        }

        // 假设当前目录的ID存储在 currentNode.id 中
        const categoryId = this.currentNode.key;
        const categoryData = {
          name: this.editFormData.directoryName,
          // 可以添加更多需要更新的字段
        };

        try {
          await updateFaqCategories(categoryId, categoryData);
          this.$message.success(this.$t('quickDiagnosis.directoryUpdatedSuccessfully'));
          this.editDialogVisible = false; // 关闭对话框
          await this.fetchTreeData(); // 重新获取目录数据，更新视图
        } catch (error) {
          this.$message.error(this.$t('quickDiagnosis.directoryUpdateFailed'));
        }
      });
    },
    resetEditForm() {
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields();
      }
    },

    resetForm() {
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields();
      }
    },
    shouldShowAddButton(node) {
      // 如果节点是顶级节点，显示按钮
      if (node.isTopLevel) {
        return true;
      }
      if (node.parent && node.parent.isTopLevel) {
        return true;
      }
      // 否则，不显示按钮
      return false;
    },
  }
};
</script>

<style scoped>
.node-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px; /* 调整宽度以适应你的需求 */
  display: inline-block;
  vertical-align: middle;
}

.custom-popover-width {
  min-width: 10px !important; /* 设置一个最小宽度 */
}

.rotate-icon {
  transform: rotate(90deg); /* 旋转90度 */
}

.left-align-buttons {
  text-align: left; /* 设置文本对齐为左对齐 */
}

.warning-message {
  text-align: center; /* 居中整个内容 */
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-bottom: 30px;
}

.warning-icon {
  font-size: 24px; /* 减小图标大小 */
  color: #f1c40f;
  margin-right: 10px; /* 在图标和文本之间添加一些间距 */
}

.warning-text {
  color: black;
  font-size: 16px;
}

.narrow-input {
  width: 85%; /* 调整为所需的宽度 */
}

:deep( .el-form-item__label ) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.icon-spacing {
  margin-right: 5px;
}

.tree-node-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* 确保占满整个父容器的宽度 */
}

.el-icon-setting {
  cursor: pointer;
}

.node-selected {
  /*background-color: rgb(238, 250, 235) !important; !* 被选中时的背景颜色 *!*/
  /* background-color: red !important; !* 被选中时的背景颜色 *!*/
  /*background-color: rgb(238, 250, 235) !important;*/
}

/* 修改 el-tree 节点点击后的背景色 */
/*.el-tree .node-selected > .el-tree-node__content {
  background-color: green !important; !* 设置点击后的背景色为绿色 *!
}*/

.node-actions-popover {
  width: auto; /* 或者指定一个具体的宽度，例如 150px */
  min-width: 30px; /* 设置一个最小宽度，根据需要调整 */
}

.node-actions-popover .el-popover {
  padding: 1px; /* 为弹出框添加内边距 */
}


.popover-action-item .el-button {
  /* 按钮样式，如有必要可以进一步调整 */
  line-height: normal; /* 调整行高以减少按钮的高度 */
  padding: 1px 5px !important;; /* 减少按钮的内边距 */
  margin: 1px !important;; /* 减小外边距 */
}

.popover-action-item {
  display: flex;
  justify-content: center; /* 水平居中 */
}

.popover-action-item:last-child {
  margin-bottom: 0; /* 最后一个按钮不需要底部间距 */
}
</style>
