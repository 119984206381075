<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div>
    <div class="custom-text">
      <span class="user-manage">{{ $t('HistoryData.UpdateRecord.historyData') }} &nbsp;/&nbsp;</span>
      {{ $t('HistoryData.UpdateRecord.updateRecord') }}
    </div>
    <hr class="separator"/>

  </div>
  <div>
    <!-- 搜索功能组件 -->
    <SearchBar @search="handleSearch"/>

    <!-- 升级记录表格 -->
    <DataTable :data="tableData" :loading="loading"/>

    <!-- 分页组件 -->
    <div class="pagination">
      <PaginationBar :totalRecords="totalRecords" :current-page="currentPage" @page-change="handlePageChange"
                     @page-size-change="handlePageSizeChange" :page-size="pageSize"/>
    </div>
  </div>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import DataTable from './components/DataTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import {getUpgradeRecords} from "@/api/api";

export default {
  components: {
    SearchBar,
    DataTable,
    PaginationBar,
  },
  data() {
    return {
      tableData: [], // 升级记录数据
      totalRecords: 0, // 总记录数
      currentPage: 1, // 当前页码
      pageSize: 10,
      isSearching: false,
      searchParams: {},
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async handleSearch(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;

      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        const response = await getUpgradeRecords(page, pageSize, this.searchParams);
        // 在这里进行数据转换
        this.tableData = response.data.map(item => ({
          serialNumber: item.id,
          productType: item.type_name,
          modelCode: item.model_code,

          parameterUpgrade: item.config_file_name,
          firmwareUpgrade: item.firmware_file_name,

          oldVersion: item.before_version,
          newVersion: item.after_version,
          Status: item.status,
          Message: item.message,

          upgradePerson: item.created_by_user,
          upgradeTime: item.created_at,
          upgradeOrganization: item.organization,
        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);

      } catch (error) {
        console.error(this.$t('ProductManage.searchFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },

    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async handlePageSizeChange(newPageSize) {

      // 异步操作的处理逻辑
      try {
        this.pageSize = newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        await this.handleSearch(this.searchParams);
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      }
    },

    async loadData() {
      this.isSearching = false;
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        const data = await getUpgradeRecords(page, pageSize);
        // 将从服务器获取的数据进行字段重命名
        this.tableData = data.data.map(item => ({
          serialNumber: item.id,
          productType: item.type_name,
          modelCode: item.model_code,
          //upgradeFile: item.firmware_file_name ? item.firmware_file_name : item.config_file_name,
          parameterUpgrade: item.config_file_name,
          firmwareUpgrade: item.firmware_file_name,
          oldVersion: item.before_version,
          newVersion: item.after_version,

          Status: item.status,
          Message: item.message,

          upgradePerson: item.created_by_user,
          upgradeTime: item.created_at,
          upgradeOrganization: item.organization,
        }));

        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta.per_page === 'string' ? parseInt(data.meta.per_page, 10) : data.meta.per_page;
      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/publicStyle.css";
</style>