<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div>
    <div class="custom-text">
      <span class="user-manage">{{ $t('HistoryData.diagnosisReport.historyData') }} &nbsp;/&nbsp;</span>
      <!--      诊断报告-->
      {{ $t('HistoryData.diagnosisReport.diagnosisRecord') }}
    </div>
    <hr class="separator"/>
  </div>

  <div>
    <!-- SearchBar 组件 -->
    <SearchBar @search="handleSearch"/>
    <!-- TableDisplay 组件 -->
    <TableDisplay :tableData="tableData" :loading="loading"/>
    <!-- PaginationDisplay 组件 -->
    <div class="pagination">
      <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                     @page-size-change="handleSizeChange" @page-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script>
import SearchBar from "./components/SearchBar.vue";
import TableDisplay from "./components/TableDisplay.vue";
import PaginationBar from "@/components/PaginationBar.vue";
//调用历史数据的诊断报告接口
import {getDiagnoseReport} from "@/api/api";

export default {
  components: {
    SearchBar,
    TableDisplay,
    PaginationBar,
  },
  data() {
    return {
      tableData: [], // 用于存储表格数据
      totalRecords: 0, // 数据总数
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      loading: false, // 加载状态
      isSearching: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 使用 async 关键字标记函数为异步
    async handleSearch(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        // 使用 await 等待异步函数的结果
        const response = await getDiagnoseReport(page, pageSize, this.searchParams);

        // 进行数据转换
        this.tableData = response.data.map(item => ({
          diagnosticTime: item.created_at,
          diagnosticPerson: item.user_name,
          diagnosticOrganization: item.organization_name,
          file_url: item.file_url,
        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);
      } catch (error) {
        // 使用 try/catch 捕获异步操作中的错误
        console.error(this.$t('ProductManage.searchFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },
    async handleSizeChange(newSize) {
      // 处理每页显示条数的变化
      this.pageSize = newSize;
      await this.handleSearch(this.searchParams); // 使用新的每页显示条数刷新数据
    },
    async handleCurrentChange(newPage) {
      // 处理当前页码的变化
      this.currentPage = newPage;
      await this.handleSearch(this.searchParams); // 使用新的页码刷新数据
    },

    async loadData() {
      this.isSearching = true; // 假设有一个isSearching状态用于显示加载中的UI
      this.loading = true; // 开始加载
      try {
        // 直接使用组件的状态（currentPage和pageSize）作为分页参数
        const page = this.currentPage;
        const pageSize = this.pageSize;

        // 从getDiagnoseReport获取数据，并传递分页和查询参数
        const data = await getDiagnoseReport(page, pageSize);
        if (!Array.isArray(data.data)) {
          throw new Error('Expected an array of data');
        }

        // 转换数据格式，根据实际返回的数据结构调整字段名
        this.tableData = data.data.map(item => ({
          diagnosticTime: item.created_at,
          diagnosticPerson: item.user_name,
          diagnosticOrganization: item.organization_name,
          file_url: item.file_url,
        })); // 更新表格数据
        this.totalRecords = data.meta.total; // 更新总记录数
        this.currentPage = data.meta.current_page; // 更新当前页（如果后端返回当前页信息）
        this.pageSize = parseInt(data.meta.per_page, 10); // 更新每页记录数

      } catch (error) {
        console.error(this.$t('ProductManage.loadDataFailed'));
        // 可以在这里处理错误，例如显示错误消息
      } finally {
        this.isSearching = false; // 加载完成，无论成功或失败
        this.loading = false; // 加载结束
      }
    },


  },

};
</script>
<style scoped>
@import "@/assets/css/publicStyle.css";

.custom-text {
  margin-left: 15px;
  /* 可以根据需要调整这个值 */
}

/* 根据屏幕宽度应用不同的样式 */
@media screen and (max-width: 768px) {
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
}
</style>